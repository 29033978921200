<template>
    <div>
        <div class="row section-header">
            <div class="col-md-9 section-title">
                <h4>Registrazione presenza</h4>
            </div>

            <div class="col-md-3 text-right" style="">

            </div>
        </div>
        <br>

        <div v-if="!sent">
            <p class="text-center">Che tipo di partecipazione è la tua?</p>
            <div class="row">
                <div class="col-6">
                    <b-button variant="secondary" v-on:click="registerInPresence"><b-icon icon="house-fill" />  In classe</b-button>
                </div>
                <div class="col-6">
                    <b-button variant="secondary" v-on:click="registerAsVirtual"><b-icon icon="cloud-fill" /> Online</b-button>
                </div>
            </div>
        </div>
        <div v-else class="response-message">
            <h3 v-if="message" class="text-success text-center">
                <b-icon icon="check-circle"/>
                <br>
                {{ message }}
            </h3>
            <h3 v-if="errorMsg" class="text-danger text-center">
                <b-icon icon="exclamation-circle"/>
                <br>
                {{ errorMsg }}
            </h3>
        </div>
    </div>

</template>

<script>
import AttendanceDataService from "@/components/attendance/AttendanceDataService";

export default {
    name: "attendance-register",
    data() {
        return {
            attendance: {
                profileId: null,
                courseId: null,
                isRemote: null,
                date: null
            },
            sent: false,
            message: '',
            errorMsg: ''
        };
    },
    methods: {
        registerAttendance() {
            AttendanceDataService.create(this.attendance)
                .then(response => {
                    if(response.data.courseId) {
                        this.message = 'Grazie per esserti registrato';
                    }
                })
                .catch(e => {
                    this.errorMsg = 'Sei gia iscritto a questo corso';
                    console.log(e);
                });
        },
        registerAsVirtual() {
            this.attendance.isRemote = true;
            this.sent = true;
            this.registerAttendance();
        },
        registerInPresence() {
            this.attendance.isRemote = false;
            this.sent = true;
            this.registerAttendance();
        },
    },
    mounted() {
        // get info passed bt ad profileId and set item
        this.attendance.courseId = this.$route.query.courseId;
        this.attendance.courseToken = this.$route.query.courseToken;
        //this.attendance.date = null; //this.$route.query.date; // La data viene segnata in automatico dal server
        this.attendance.profileId = this.$store.state.user.profileId;
    }
};
</script>

<style scoped>
.response-message {
    padding: 40px;
    background-color: #fff;
}
</style>
