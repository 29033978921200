import http from "../../helpers/http-common";

class AttendanceDataService {
    getAll(courseId, courseToken) {
        return http.get(`/attendances/${courseId}/${courseToken}`);
    }

    getAllByStudent(studentId) {
        return http.get(`/attendances/student/${studentId}`);
    }

    create(data) {
        return http.post("/attendances", data);
    }

    update(id, data) {
        return http.put(`/attendances/${id}`, data);
    }

    delete(id) {
        return http.delete(`/attendances/${id}`);
    }
}

export default new AttendanceDataService();
